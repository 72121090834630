export const getMetaData = (items) => {
  let meta = '';
  const maxlength = 20;
  if (items && items.length > 0) {
    items.forEach(element => {
      meta += element.key + ': ' + element.value + '\n';
    });
  }
  return meta.length > maxlength
    ? meta.substring(0, maxlength - 3) + '...'
    : meta.substring(0, maxlength);
}