import {truncate} from 'lodash';

export const textShorten = (text, length = 12) => {
  if (text) {
    return truncate(text, {
      length,
      omission: '...'
    })
  } else {
    return ''
  }

}

const selectText = (element) => {
  let range;
  if (document.selection) {
    // IE
    range = document.body.createTextRange();
    range.moveToElementText(element);
    range.select();
  } else if (window.getSelection) {
    range = document.createRange();
    range.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
}


export const copyToClipboard = (element) => {
  selectText(element);
  document.execCommand("copy");
}