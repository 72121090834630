<template>
  <CRow class="dependantOn-detail">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="isCollapsedHandler">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.createPage.summary.entrants.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!--       Opt in require     -->
            <ClCardRow :label="texts.summary.entrants.optLabel" :content="optinRequiredForEntrants.toString()" />
            <!--       Everyone can participate     -->
            <ClCardRow :label="texts.summary.entrants.everyoneText" :content="'true'" v-if="isEveryoneCanParticipate"/>
            <!-- Should Match At Least -->
            <ClCardRow
              v-if="entrantsData.formData.shouldMatchAtLeast"
              :label="texts.createPage.summary.dependantOn.shouldLabel"
              :content="entrantsData.formData.shouldMatchAtLeast"
            />
            <!-- TABLE -->
            <CDataTable
              v-if="entrantsData.selectedData.length"
              id="achTableResult"
              class="zq--table zq--table--default-pagination"
              :items="entrantsData.selectedData"
              :fields="fields"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              pagination
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              @update:sorter-value="handleSortBy"
            >
              <!--      CREATED         -->
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <!--       Description           -->
              <template #description="{ item }">
                <td>
                  <span v-html="item.description"></span>
                </td>
              </template>
              <!--       Dependency           -->
              <template #dependency="{ item }">
                <td>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="action-buttons-list">
                      <button
                        class="action-buttons action-buttons__must-not"
                        :class="{'action-buttons--active__must-not': entrantsData.formData.mustNot.includes(item.key)}"
                      >
                        {{ texts.summary.entrants.mustNotText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__should"
                        :class="{'action-buttons--active__should': entrantsData.formData.should.includes(item.key)}"
                      >
                        {{ texts.summary.entrants.shouldText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__must"
                        :class="{'action-buttons--active__must': entrantsData.formData.must.includes(item.key)}"
                      >
                        {{ texts.summary.entrants.mustText }}
                      </button>
                    </div>
                  </div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClCardRow from '@/shared/UI/ClCardRow';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import {mapActions, mapGetters} from "vuex";
import {competitionsTexts} from "@/config/pageTexts/competitions.json";
import {pageConfig} from "@/config";
import {dateFormate} from "@/utils";

export default {
  name: 'ViewDependantOn',
  components: {
    ClCardRow,
    ClCardArrow
  },
  props: {
    dependantOnData: Object,
    constraints: Array,
  },
  data() {
    return {
      texts: {
        ...competitionsTexts
      },
      basicIsCollapsed: false,
      optinRequiredForEntrants: false,
      isEveryoneCanParticipate: false,
      fields: [
        "key",
        "created",
        "name",
        "dependency"
      ],
      itemsPerPage: pageConfig.itemsPerPage,
      entrantsData: {
        selectedData: [],
        formData: {},
      },
      dependantOnLocalData: {},
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('tags', ['handleGetTags', 'handleGetTagsByQuery']),
    initialize() {
      this.dependantOnLocalData.must = this.dependantOnData.must ?? [];
      this.dependantOnLocalData.mustNot = this.dependantOnData.mustNot ?? [];
      this.dependantOnLocalData.should = this.dependantOnData.should ?? [];

      if (![...this.dependantOnLocalData.must, ...this.dependantOnLocalData.mustNot, ...this.dependantOnLocalData.should].length) {
        this.isEveryoneCanParticipate = true;
      }

      if (this.constraints && this.constraints.includes('optinRequiredForEntrants')) {
        this.optinRequiredForEntrants = true;
      }

      this.entrantsData.formData = this.dependantOnLocalData;
    },
    isCollapsedHandler() {
      this.basicIsCollapsed = !this.basicIsCollapsed;

      if (this.basicIsCollapsed  && !this.entrantsData.selectedData.length) this.getSelectedData();
    },
    async getSelectedData() {
      const selectedTags = [
        ...this.dependantOnLocalData.must,
        ...this.dependantOnLocalData.mustNot,
        ...this.dependantOnLocalData.should
      ];

      if (selectedTags.length) {
        this.entrantsData.selectedData = await this.handleGetTagsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'key',
                queryValues: selectedTags
              }
            ],
            limit: selectedTags.length
          }
        });
      }
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    async handleSortBy(e) {

      const selectedTags = [
        ...this.dependantOnLocalData.must,
        ...this.dependantOnLocalData.mustNot,
        ...this.dependantOnLocalData.should
      ];

      if (selectedTags.length) {
        this.entrantsData.selectedData = await this.handleGetTagsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'key',
                queryValues: selectedTags
              }
            ],
            sortBy: [
              {
                queryField: e.column,
                order: e.asc ? 'Asc' : 'Desc',
              },
            ],
            limit: selectedTags.length
          }
        });
      }
    },
  },
}
</script>

<style lang="scss">
.dependantOn-detail {

}
</style>
