import {uniq, concat} from 'lodash';

export const getTranslatableFields = (item) => {
  if (item.translations) {
    const translatable = item.translations.map((item) => {
      return item.translatedFields.map(item => item.fieldName)
    });

    return uniq(concat([],...translatable)).join(',');
  } else {
    return item.translatableFields ? item.translatableFields.join(',') : ''
  }
}